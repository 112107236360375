import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./App.css"

// const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(
    <App />,
    document.getElementById('root')
);

